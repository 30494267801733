import React from 'react';
import Translate from 'react-translate-component';

export default function BoxText(props)
{
    return(
        <div className="tc wow fadeInLeft ntext scrollbox"
                data-wow-duration="1s" data-wow-offset="300"
                data-wow-delay={props.delay}>
            <Translate content={props.text} component="span"/>
        </div>
    )
}
