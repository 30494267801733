import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate from 'react-translate-component';

export default function Subtitle(props)
{
    return(
        <Row>
            <Col>
                <div className="tc mlat wow fadeInUp scrollbox subtitle" 
                        data-wow-duration="1s" data-wow-offset="150"
                        data-wow-delay={props.delay}>
                    <Translate content={props.text} component="span"/>
                </div>
            </Col>
        </Row>
    )
}
