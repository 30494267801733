import React from 'react';

export default function BoxText(props)
{
    return(
        <div className="tc wow fadeIn ntext imagebox"
                data-wow-duration="1s" data-wow-offset="300"
                data-wow-delay={props.delay}>
            <iframe className="imgauto imgborder" title="Trailer" src={props.ytsrc}/>
        </div>
    )
}
