import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Images
import img_title from '../img/title.png';

export default function Title()
{
    return(
        <Row>
            <Col className="tc">
                <div className="wow fadeInDown">
                    <img src={img_title} className="imgtitle" alt="title" />
                </div>
            </Col>
        </Row>
    )
}