import i1 from '../img/items/item_1.png';
import i2 from '../img/items/item_2.png';
import i3 from '../img/items/item_3.png';
import i4 from '../img/items/item_4.png';
import i5 from '../img/items/item_5.png';
import i6 from '../img/items/item_6.png';
import i7 from '../img/items/item_7.png';
import i8 from '../img/items/item_8.png';
import i9 from '../img/items/item_9.png';
import i10 from '../img/items/item_10.png';
import i11 from '../img/items/item_11.png';
import i12 from '../img/items/item_12.png';
import i13 from '../img/items/item_13.png';
import i14 from '../img/items/item_14.png';
import i15 from '../img/items/item_15.png';
import i16 from '../img/items/item_16.png';
import i17 from '../img/items/item_17.png';
import i18 from '../img/items/item_18.png';


export default [
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
    i18
];