export default {
    title:"Order’s Quest",
    subtitle_01:"Explora, combate y construye a la orden",
    subtitle_02:"Recupera la paz en la isla cascada",
    subtitle_03:"Derrota temibles enemigos y jefes",
    subtitle_04:"Consigue numerosos tesoros",
    subtitle_05:"¡Juega Ahora! Gratis y sin anuncios",
    subtitle_06:"Apoyo y Soporte",
    text_01:"Order´s Quest es un juego RPG con combates por turnos y un mundo abierto por descubrir. Forma una orden de guerreros para acabar con el mal que atormenta al los diferentes reinos.",
    text_02:"Descargar",
    text_03:"Próximamente",
    text_04:"¿Necesitas ayuda? Escríbenos y resolveremos cualquier duda"
}
