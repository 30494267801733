import React, {useEffect, useState} from 'react';
import './App.css';

import WOW from 'wowjs';//npm install wowjs
import counterpart from 'counterpart';//npm install react counterpart - react-interpolate-component
import Translate from 'react-translate-component'; //npm install react-translate-component --save

//Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import es from './lang/es';

//Images
import IlsImg01 from './img/Ilustration_01.png';
import IlsImg02 from './img/Ilustration_02.png';
import IlsImg03 from './img/Ilustration_03.png';
import IlsImg04 from './img/Ilustration_04.png';
import Ene01 from './img/enemys/ene_01.gif';
import Ene02 from './img/enemys/ene_02.gif';
import Ene03 from './img/enemys/ene_03.gif';
//import Plat01 from './img/plataforms/android.png';
//import Plat02 from './img/plataforms/ios.png';
import Plat03 from './img/plataforms/windows.png';

//Components
import Title from './components/Title';
import Subtitle from './components/Subtitle';
import BoxText from './components/BoxText';
import BoxImage from './components/BoxImage';
import BoxVideo from './components/BoxVideo';

//Assets
import imgs_items from './assets/imgs_items';

counterpart.registerTranslations('es',es);

counterpart.setLocale('es');

export default function App() {

  const [init,setInit] = useState(false);
  const [yscroll, setYscroll] = useState(0);

  useEffect(() => {

    if (!init)
    {
      new WOW.WOW({
          live: false
      }).init();
      setInit(true);
      window.onscroll = () =>{
        const blur = Math.round(window.pageYOffset*0.01);
        setYscroll(blur > 8 ? 8 : blur);
      };
    }
    
  },[init,yscroll]);

  const bkblur = {
    filter: `blur(${yscroll}px)`
  };

  return (
    <div className="App">
      <div className="bkimage" style={bkblur}></div>
      <div className="main">
        <Container fluid>
          <Title/>
        </Container>
        <Container><Subtitle delay="1s" text="subtitle_01"/></Container>
        <Container><Resum/></Container>
        <Container><Subtitle text="subtitle_02"/></Container>
        <Container><Video/></Container>
        <Container><Subtitle text="subtitle_03"/></Container>
        <Container><Enemys/></Container>
        <Container><Subtitle text="subtitle_04"/></Container>
        <Container><Items/></Container>
        <Container><Subtitle text="subtitle_05"/></Container>
        <Container><Plataforms/></Container>
        <Container><Subtitle text="subtitle_06"/></Container>
        <Container><Suport/></Container>
        <Footer/>
      </div>
    </div>
  );
}

function Resum()
{
  return(
    <Row className="mlat align-items-center">
      <Col xs={12} sm={12} md={6} lg={6}>
        <BoxText delay="0.5s" text="text_01"/>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <BoxImage delay="1s" srcimg={IlsImg02}/>
      </Col>
    </Row>
  );
}

function Video()
{
  return(
    <Row>
      <Col>
        <BoxVideo ytsrc="https://www.youtube.com/embed/kcCZyymeeG0"/>
      </Col>
    </Row>
  );
}

function Enemys()
{
  return(
    <Row className="mlat align-items-center">
      <Col xs={12} sm={12} md={6} lg={6}>
        <BoxImage srcimg={IlsImg01}/>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <div className="tc wow fadeInUp ntext scrollbox"
            data-wow-duration="1s">
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={4}
                  className="wow fadeInUp" data-wow-delay="0.9s"><img src={Ene03} alt="imgbox"/></Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4}
                  className="wow fadeInUp" data-wow-delay="0.6s"><img src={Ene02} alt="imgbox"/></Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4}
                  className="wow fadeInUp" data-wow-delay="0.3s"><img src={Ene01} alt="imgbox"/></Col>
              </Row>
        </div>
      </Col>
    </Row>
  );
}

function Items()
{
  const rewards = [];
  for (var i=0; i<18; i++)
    rewards.push(i);

  const listrewards = rewards.map((reward) =>
    <Reward delay={(reward/8)+"s"} image={imgs_items[reward]} index={reward} key={reward.toString()}/>
  );

  return(
    <Row className="mlat align-items-center">
      <Col xs={12} sm={12} md={6} lg={6}>
        <div className="tc wow fadeInUp ntext scrollbox"
            data-wow-duration="1s">
              <Row>
                {listrewards}
              </Row>
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <BoxImage srcimg={IlsImg03}/>
      </Col>
    </Row>
  );
}

function Reward(props)
{
  return (
    <Col xs={4} sm={4} md={4} lg={3} xl={2} className="tc">
        <div className="p4 wow fadeIn" data-wow-duration="0.2s" data-wow-delay={props.delay}>
          <img src={props.image} alt="reward" />
        </div>
    </Col>
  )
}

function Plataforms()
{
  return(
    <Row className="mlat align-items-center">
      {/* <Download icon={Plat01} link="https://play.google.com/store/apps/details?id=com.frenzygamesstudio.qrdersquest.FrenzyGamesStudio.OrdersQuest"/>
      <Download icon={Plat02} delay="0.5s" link="https://apps.apple.com/mx/app/order-s-quest/id1533601390"/> */}
      <Col xs={12} sm={12} md={4} lg={4}></Col>
      <Download icon={Plat03} link="http://frenzygamesstd.com/downloads/ordersquest/OrdersQuest.zip"/>
      <Col xs={12} sm={12} md={4} lg={4}></Col>
    </Row>
  );
}

function Download(props)
{
  return(
    <Col xs={12} sm={12} md={4} lg={4}>
      <div className="tc wow fadeInLeftBig ntext scrollbox fs2" 
           data-wow-offset="100" data-wow-delay={props.delay}>
        <div className="p8">
          <img src={props.icon} alt="plataform" className="imgsmall"/>
        </div>
        <br/>
        <Button className="ffaugusta fsp24" 
                href={props.link}>
          <Translate content="text_02"/>  
        </Button>
      </div>
    </Col>
  );
}

function Suport()
{
  return(
    <Row className="mlat align-items-center">
      <Col xs={12} sm={12} md={6} lg={6}>
        <div className="tc wow fadeInLeft ntext scrollbox"
                data-wow-duration="1s"
                data-wow-delay="0.5s">
            <Translate content="text_04" component="p"/>
            <Button href="mailto:moiseslopez@frenzygamesstd.com" variant="primary" size="lg">
              <FontAwesomeIcon icon={faPaperPlane} size="2x"/>
            </Button>
        </div>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <BoxImage delay="1s" srcimg={IlsImg04}/>
      </Col>
    </Row>
  );
}

/*
<Button href="#" variant="success" size="lg" className="fs3 ffaugusta">
      <Translate content="text_02"/>
</Button>
*/
function Footer()
{
  return(
    <Container>
      <Row className="mlat align-items-center">
        <Col>
          <div className="tc wow fadeIn p8">
            <a href="http://www.frenzygamesstd.com" rel="noopener noreferrer"
                className="black" target="_blank">Frenzy Games Std 2020</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}