import React from 'react';

export default function BoxText(props)
{
    return(
        <div className="tc wow fadeInRight ntext imagebox"
                data-wow-duration="1s" data-wow-offset="300"
                data-wow-delay={props.delay}>
            <img src={props.srcimg} className="imgauto imgborder" alt="imgbox"/>
        </div>
    )
}
